import { useQuery } from "@tanstack/react-query";
import ContentClient from "@/client/ContentClient";

const contentClient = new ContentClient();

export const getPromoStripQuery = () =>
  useQuery({
    queryKey: ["getPromoStripContent"],
    queryFn: () => contentClient.getPromoStripContent(),
  });

export const getHeaderSlides = () =>
  useQuery({
    queryKey: ["getHeaderSlidesContent"],
    queryFn: () => contentClient.getHeaderSlideContent(),
  });

export const getEmployeeTestimonials = () =>
  useQuery({
    queryKey: ["getEmployeeTestimonialsContent"],
    queryFn: () => contentClient.getEmployeeTestimonialsContent(),
  });

export const getCurrentCourse = () =>
  useQuery({
    queryKey: ["currentCourse"],
    queryFn: (id) => contentClient.getCourseContent(id),
  });
