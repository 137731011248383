import colours from "./colours";

export default {
  teal: "#00605C", // All primary buttons and CTAs
  charcoal: "#414143", // All text
  charcoalMid: "#414143BB", // Placeholder text
  charcoalFaded: "#41414333", // Placeholder text
  plusLime: "#E3E989", // Anything relating to Paid Digital
  purple: "#E5D7F2", // Information banners or buttons
  errorRed: "#EB5757", // Text on error messages or icons
  errorYellow: "#ffc500", // Background of error blocks
  academyOrange: "#D68B76", // Bookmarks and free content in Academy
  mentorDarkBlue: "#135197", // Dark background for mentorship
  mentorLemon: "#EBE459", // Highlights for mentorship
  buntingBlue: "#16264E", // subnav
  deYorkGreen: "#9CCB95", // Recommendations: Event badge
  kimberlyPurple: "#8A70A4", // Recommendations: Academy badge
  myPink: "#D48585", // Recommendations: Opportunity badge
  tumbleweedBeige: "#E2B48A", // Recommendations: Membership badge

  lightShell: "#FFFBF9", //Highlighted backgrounds such as businessCard
  shell: "#F9F4F0", // All backgrounds of digital product
  shellOverlay: "#F2E7DF", // Overlays, containers or sections that differ from the main background
  shellGuideline: "#E3D2C6", // Divider lines and markings to denote pause, borders of cards
  shellHighlight: "#FFFBF8", // backgrounds within highlight blocks and sections
  white: "#FFFFFF",
  black: "#000000",

  turquoise: "#06BDB6", // Accents that don't have text over the top
  textTurquoise: "#59A39F", // Highlighted parts of text
  pillTeal: "#C8E8E5", // Selected state for pills
  pillTealHover: "#99C9C9", // Hover state for selected pills
  freeBanana: "#F9DC81", // Anything promoting the Free platform

  clubBlue: "#3C3F5F", // Navy blue used for Club app
  clubGold: "#DCCA8E", // Gold used for Club app accents
  corporateOrange: "#FFA471", // Orange used for corporate membership
  corporateGreen: "#0f3131", // Green used for corporate membership

  elevatePurple: "#52578B", // Purple used for Elevate
  elevateLightPurple: "#7880B8", // Light purple used for Elevate
  elevateHighlight: "#BBC3E3", // Purple used for Elevate highlights

  accelerateOrange: "#D88A73", // Orange used for Accelerate
  accelerateLightOrange: "#E69D85", // Light orange used for Accelerate
  accelerateHighlight: "#FFE1D8", // Orange used for Accelerate Highlights

  giftGreen: "#094543", // Green used for gifting
  giftDarkGreen: "#0E4C49", // Dark green used for gifting
  giftLightGreen: "#00605C", // light Green used for gifting
  moneyGold: "#D7BF7B", // Gold

  marketingYellow: "#FFD869", // Yellow used for homepage / marketing
  marketingNavy: "#222B59", // Navy used for homepage / marketing
  marketingBlue: "#5869AD", // Blue used for homepage / marketing
  marketingGreen: "#005651", // Green used for homepage / marketing
  marketingGreenDark: "#003525", // Green used for homepage / marketing
  marketingGreenCoach: "#074441", // Green used for memberships / learning programme
  marketingOrange: "#F5AC97", // Orange used for homepage / marketing
  marketingBeige: "#F0E3DB", // Beige used for homepage / marketing
  marketingMango: "#DE907E",
  marketingTeal: "#047572",
  marketingBusinessNavy: "#020A38",
  marketingYellowPale: "#FDE191",
  marketingOrangePale: "#D17963",
  marketingBluePale: "#777FB7",
  marketingPeach: "#FBBDA8",
  marketingPurple: "#5D5492",
  marketingTealPale: "#2A736F",

  stepForwardNavy: "#1D1A33", // Navy used for Step Forward page
  stepForwardLilac: "#D5DEFC", // Lilac used for Step Forward page
  stepForwardOrange: "#E88A79", // Orange used for Step Forward page
  stepForwardBlue: "#2B1C68", // Blue used for Step Forward page

  plusColours: [
    "#50968D",
    "#617595",
    "#886BB7",
    "#834973",
    "#955C5C",
    "#D18D66",
    "#D3B137",
    "#6D8868",
  ],
};

export const recommendationColour = {
  article: colours.marketingYellow,
  connection: "#3A7D6D",
  course: "#DE8466",
  event: "#59A39F",
  group: "#7D4282",
  mentorship: colours.charcoal,
  programme: "#555C9A",
};
