import { useQuery } from "@tanstack/react-query";
import EventClient from "@/client/EventClient";

const eventClient = new EventClient();

export const getUserEvents = (userId: string) =>
  useQuery({
    queryKey: ["getUserEvents", userId],
    queryFn: () => eventClient.getUserSavedEvents(userId),
  });

export const getEventById = (eventId: string) =>
  useQuery({
    queryKey: ["getEventById", eventId],
    queryFn: () => eventClient.getEventById(eventId),
  });

export const getUserEventIds = (userId: string) =>
  useQuery({
    queryKey: ["getUserEventIds", userId],
    queryFn: () => eventClient.getUserSavedEventIds(userId),
  });

export const getUpcomingEvents = (limit: number) =>
  useQuery({
    queryKey: ["getUpcomingEvents", limit],
    queryFn: () => eventClient.getUpcomingEvents(limit),
  });

export const getAllUpcomingEvents = (limit: number) =>
  useQuery({
    queryKey: ["getAllUpcomingEvents", limit],
    queryFn: () => eventClient.getUpcomingEvents(limit),
  });
