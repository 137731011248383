import axios from "axios";
import config from "@/config";
import configClient from "@/config.client";
import * as Sentry from "@sentry/nextjs";
import HeaderSlideContent from "@/types/marketing/HeaderSlideContent";
import PromoStripContent from "@/types/marketing/PromoStripContent";
import EmployeeTestimonial from "@/types/marketing/EmployeeTestimonial";
import { Course } from "@/types/learning/Course";

class ContentClient {
  private readonly contentfulGeneral: string;

  constructor() {
    this.contentfulGeneral =
      config.CONTENTFUL_GENERAL || configClient.CONTENTFUL_GENERAL;
  }

  public async getHeaderSlideContent(): Promise<HeaderSlideContent[]> {
    try {
      const response = await axios.post(
        this.contentfulGeneral,
        this.getHeaderSlideQuery()
      );

      const items = response?.data?.data?.headerSlideCollection?.items;

      if (!items || items.length < 1) {
        throw new Error("No header slide data returned.");
      }

      return items;
    } catch (error) {
      Sentry.captureException(error);
      throw new Error("Error fetching header slides: " + error.message);
    }
  }

  public async getPromoStripContent(): Promise<PromoStripContent[]> {
    try {
      const response = await axios.post(
        this.contentfulGeneral,
        this.getPromoStripQuery()
      );
      const items = response?.data?.data?.promoStripCollection?.items;

      if (!items || items.length < 1) {
        return [];
      }

      return items;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }

  private getHeaderSlideQuery() {
    return {
      query: `{
        headerSlideCollection {
          items {
            subtitleFirst
            subtitleMiddle
            subtitleLast
            subtitleColour
            fontColour
            backgroundColour
            title
            copy
            linkText
            linkUrl
            linkLineColour
            copyPosition
            vimeoUrl
            image {url title description}
          }
        }
      }`,
    };
  }

  private getPromoStripQuery() {
    return {
      query: `{
        promoStripCollection {
          items {
            title
            copy
            linkText
            linkUrl
            backgroundColour
            textColour
            buttonUrl
            activePages
          }
        }
      }`,
    };
  }

  public async getHomepageHeroAdContent(): Promise<HeaderSlideContent[]> {
    try {
      const response = await axios.post(
        this.contentfulGeneral,
        this.getHomepageHeroAdQuery()
      );
      const homepageHeroAd =
        response?.data?.data?.homepageHeroAdCollection?.items[0];

      if (!homepageHeroAd) {
        throw new Error("No homepage hero ad returned.");
      }

      return homepageHeroAd;
    } catch (error) {
      Sentry.captureException(error);
      throw new Error("Error fetching homepage hero ad: " + error.message);
    }
  }

  private getHomepageHeroAdQuery() {
    return {
      query: `{
        homepageHeroAdCollection {
          items {
            description
            image {url title}
            imageAlt
            linkText
            linkUrl
            title
          }
        }
      }`,
    };
  }

  public async getEmployeeTestimonialsContent(): Promise<
    EmployeeTestimonial[]
  > {
    try {
      const response = await axios.post(
        this.contentfulGeneral,
        this.getEmployeeTestimonialsQuery()
      );
      const employeeTestimonials =
        response?.data?.data?.employeeTestimonialCollection?.items;

      if (!employeeTestimonials) {
        throw new Error("No employee testimonials returned.");
      }

      return employeeTestimonials;
    } catch (error) {
      Sentry.captureException(error);
      throw new Error("Error fetching employee testimonials: " + error.message);
    }
  }

  private getEmployeeTestimonialsQuery() {
    return {
      query: `{
        employeeTestimonialCollection {
          items {
            employeeName
            employeeJobTitle
            quoteBold
            quoteRegular
            vimeoUrl
          }
        }
      }`,
    };
  }

  public async getCourseContent(id): Promise<Course> {
    try {
      const response = await axios.post(
        this.contentfulGeneral,
        this.getCourseQuery(id)
      );
      const homepageHeroAd =
        response?.data?.data?.homepageHeroAdCollection?.items[0];

      if (!homepageHeroAd) {
        throw new Error("No course returned.");
      }

      return homepageHeroAd;
    } catch (error) {
      Sentry.captureException(error);
      throw new Error("Error fetching course: " + error.message);
    }
  }

  private getCourseQuery(id) {
    return {
      query: `{
        lesson(id: "${id}") {
          lessonName
          lessonIntroductionText
          courseTags
          categoryTags
          isFree
          lessonAuthor
          sys { id }
          coverImage { title url }
          lessonAuthorAvatar { url }
          authorBiography { json }
          lessonActivitiesCollection {
            items {
              activityTitle
              vimeoUrl
              runtime
              audio { url }
              sys { id }
              activityIntroduction { json }
            }
          }
          courseWorksheet {
            url
          }
          readingListUse
          additionalDownloadableResourcesCollection {
            items {
              title
              description
              url
            }
          }
        }
      }`,
    };
  }
}

export default ContentClient;
